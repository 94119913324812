import { useEffect, useState } from "react";

export const useIsVisibleToScroll = (elementRef) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef?.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementIsVisible =
          rect.top >= 0 && rect.bottom <= window.innerHeight;
        setIsVisible(elementIsVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { isVisible };
};
