import React, { useContext } from "react";
import styles from "./sidebar.module.scss";
import { SideBarContext } from "../../App";
import { SIDEBAR_FORM_TYPE } from "../../assets/constants/sideBar";
import ApplicationForm from "../forms/ApplicationForm";
import SidebarForm from "../forms/SidebarForm";

const SideBar = () => {
  const { sideBarOptions, setSideBarOptions } = useContext(SideBarContext);

  const closeSideBar = () => {
    setSideBarOptions((prevOptions) => ({
      ...prevOptions,
      formType: SIDEBAR_FORM_TYPE.SIDEBAR_FORM,
      isOpen: !prevOptions.isOpen,
    }));
  };

  return (
    <aside
      className={`${styles.sidebar} ${
        sideBarOptions.isOpen ? styles.active : ""
      }`}
      onClick={closeSideBar}
    >
      <div
        className={`${styles.content} ${
          sideBarOptions.isOpen ? styles.active : ""
        }`}
        onClick={(event) => event.stopPropagation()}
      >
        {sideBarOptions.formType === SIDEBAR_FORM_TYPE.APPLICATION && (
          <ApplicationForm />
        )}
        {sideBarOptions.formType === SIDEBAR_FORM_TYPE.SIDEBAR_FORM && (
          <SidebarForm />
        )}
      </div>
    </aside>
  );
};

export default SideBar;
