import React from "react";
import { ReactComponent as Logo } from "./../../assets/img/logo.svg";

import styles from "./footer.module.scss";
import QuestionForm from "../forms/QuestionForm";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "FOOTER" });
  return (
    <footer className={`${styles.footer} container`}>
      <div className={styles.footer_left}>
        <p>{t("text")}</p>
        <a target="_blank" href="sencex.pdf">
          {t("privacy")}
        </a>
        <Logo />
      </div>
      <QuestionForm customStyles={{}} />
    </footer>
  );
};

export default Footer;
