import brown from "../images/sole_browns.png";
import grey from "../images/sole_grey.png";
import black from "../images/sole_black.png";
import darkgreen from "../images/sole_dark_green.png";
import darkpurple from "../images/sole_dark_purple.png";
import darkperf from "../images/sole_dark_perf.png";
import lightgreyperf from "../images/sole_light_grey_perf.png";
import beige from "../images/sole_beige.png";
import alk from "../images/sole_light_alk.png";
import darkgrey from "../images/sole_dark_grey.png";

export const INITIAL_SLIDES = [
  {
    id: 1,
    caption: "GLeather",
    description: "text4",
    image: brown,
    colors: [
      {
        id: 1,
        code: "rgba(105, 100, 101, 1)",
        image: brown,
      },
      {
        id: 2,
        code: "rgba(193, 194, 195, 1)",
        image: grey,
      },
      {
        id: 3,
        code: "rgba(41, 41, 41, 1)",
        image: black,
      },
    ],
  },
  {
    id: 2,
    caption: "suede",
    description: "suede_text",
    image: darkgreen,
    colors: [
      {
        id: 1,
        code: "rgba(50, 62, 39, 1)",
        image: darkgreen,
      },
      {
        id: 2,
        code: "rgba(68, 53, 79, 1)",
        image: darkpurple,
      },
    ],
  },
  {
    id: 3,
    caption: "microFiber",
    description: "microFiber_text",
    image: darkperf,
    colors: [
      {
        id: 1,
        code: "rgba(41, 41, 41, 1)",
        image: darkperf,
      },
      {
        id: 2,
        code: "rgba(193, 194, 195, 1)",
        image: lightgreyperf,
      },
    ],
  },
  {
    id: 4,
    caption: "alcantara",
    description: "microFiber_text",
    image: beige,
    colors: [
      {
        id: 1,
        code: "rgba(250, 239, 221, 1)",

        image: beige,
      },
      {
        id: 2,
        code: "rgba(223, 223, 223, 1)",
        image: alk,
      },
      {
        id: 3,
        code: "rgba(193, 194, 195, 1)",
        image: darkgrey,
      },
    ],
  },
];
