import React from "react";
import styles from "./healthy-journey.module.scss";
import { EffectFade, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

const HealthyJourney = () => {
  const { t } = useTranslation("translation", { keyPrefix: "HEALTHY_JOURNEY" });
  const pagination = {
    clickable: true,
    renderBullet: function (_, className) {
      return `<span class="${className} ${styles.swiperPaginationBullet}"></span>`;
    },
  };
  return (
    <section className={styles.wrapper}>
      <Marquee speed={160}>
        <h1 className={styles.title}>{t("title")}</h1>
      </Marquee>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        pagination={pagination}
        autoplay={{
          delay: 2000,
        }}
        speed={2000}
        loop={true}
        fadeEffect={{ crossFade: true }}
        modules={[EffectFade, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className={styles.slide}>
          <div className={styles.text}>
            <h1>
              {t("health")}
              <br />
              <span>{t("athletes")}</span>
            </h1>
            <p>{t("text")}</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HealthyJourney;
