import React, { useContext } from "react";
import { ReactComponent as Logo } from "./../../assets/img/logo.svg";
import styles from "./header.module.scss";
import LangSwitcher from "./LangSwitcher";
import { SideBarContext } from "../../App";
import { SIDEBAR_FORM_TYPE } from "../../assets/constants/sideBar";
const Header = () => {
  const { sideBarOptions, setSideBarOptions } = useContext(SideBarContext);

  const toggleSidebarOpen = () => {
    setSideBarOptions((prevOptions) => ({
      ...prevOptions,
      formType: SIDEBAR_FORM_TYPE.SIDEBAR_FORM,
      isOpen: !prevOptions.isOpen,
    }));
  };

  return (
    <header
      className={`${styles.header_sticky} ${
        sideBarOptions.isOpen ? styles.sidebar_open : ""
      }`}
    >
      <div className={`container ${styles.header}`}>
        <Logo />
        <div className={styles.header_right}>
          <LangSwitcher />
          <div
            className={`${styles.menuBtn} ${
              sideBarOptions.isOpen ? styles.active : ""
            }`}
            onClick={toggleSidebarOpen}
          >
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
