/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 scene.gltf 
*/

import React, { useRef } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  useAnimations,
  useTexture,
} from "@react-three/drei";

import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

export function Insole(props) {
  const { scene } = useGLTF("/insole/scene.gltf");

  return (
    <>
      <primitive
        object={scene}
        scale={16}
        position={[0, -1.6, 0]}
        rotation={[3.367, -6, 3.01]}
      />
    </>
    // <group ref={group} {...props} dispose={null}>
    //   <group>
    //     <group
    //       name="CoronaCamera001Target"
    //       position={[-0.114, 0.182, -0.141]}
    //       rotation={[2.957, 0.564, 2.99]}
    //     />
    //     <group name="CoronaSun001Target" position={[0.445, 0, -9.678]} />
    //     <group name="Group001" position={[0, 0, 0]}>
    //       <mesh
    //         name="Stelka_end"
    //         geometry={nodes.Stelka_end.geometry}
    //         material={materials["Material #38"]}
    //         position={[0.041, 0.003, 0.016]}
    //         rotation={[3.047, -0.182, 0.988]}
    //         scale={[1, -1, 1]}
    //       >
    //         <meshStandardMaterial
    //           normalMap={normalMap}
    //           roughnessMap={roughnessMap}
    //           map={colorMap}
    //         />
    //       </mesh>
    //       <mesh
    //         name="Stelka_end001"
    //         geometry={nodes.Stelka_end001.geometry}
    //         material={materials["Material #38"]}
    //         position={[-0.066, -0.029, -0.001]}
    //         rotation={[-2.532, 0.98, 1.876]}
    //       >
    //         <meshStandardMaterial
    //           normalMap={normalMap}
    //           roughnessMap={roughnessMap}
    //           map={colorMap}
    //         />
    //       </mesh>
    //     </group>
    //     <group
    //       name="CoronaSun001"
    //       position={[19.142, 13.011, -30.509]}
    //       rotation={[1.904, 0.285, -2.458]}
    //     />
    //     <group
    //       name="CoronaLight001"
    //       position={[-1.574, -0.267, 1.856]}
    //       rotation={[Math.PI / 2, 0, 0]}
    //     />
    //     <PerspectiveCamera
    //       name="CoronaCamera001"
    //       makeDefault={false}
    //       far={10000}
    //       fov={18.875}
    //       position={[-0.581, 0.436, -1.002]}
    //       rotation={[-2.855, -0.48, -3.006]}
    //     />
    //   </group>
    // </group>
  );
}

useGLTF.preload("/scene.gltf");
