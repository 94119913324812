import React from "react";
import styles from "./Modal.module.scss";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

const Modal = ({ isSuccess, onClose }) => {
  const { t } = useTranslation("translation", { keyPrefix: "MODAL" });

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose(false);
      window.location.reload();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.content}>
        <div className={styles.modalContent}>
          {isSuccess ? <p>{t("success")}</p> : <p>{t("error")}</p>}
        </div>
        <button
          onClick={() => {
            onClose(false);
            window.location.reload();
          }}
        >
          <p>{t("close")}</p>
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
