import React, { useEffect, useState } from "react";
import { ReactComponent as USA } from "../../../assets/img/usa.svg";
import { ReactComponent as France } from "../../../assets/img/FR.svg";
import { ReactComponent as Italy } from "../../../assets/img/IT.svg";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import styles from "./lang-switcher.module.scss";

const switchers = [
  {
    icon: <France width={45} />,
    code: "fr",
  },
  {
    icon: <Italy width={45} />,
    code: "it",
  },
  {
    icon: <USA width={45} />,
    code: "en",
  },
];

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState("en");
  const [open, setOpen] = useState(false);

  const handleOpenSelect = () => setOpen((prevState) => !prevState);

  const changeLanguage = (langCode) => {
    i18n.changeLanguage(langCode);
    setActiveLang(langCode);
    setOpen(false);
  };

  const currentLanguageCode = i18n.language;

  console.log("languege", currentLanguageCode);

  useEffect(() => {
    setActiveLang(currentLanguageCode);
  }, [currentLanguageCode]);

  const options = switchers.map(({ code, icon }) => ({
    value: code,
    label: icon,
  }));

  console.log("options", options);

  const swithcerFiltered = switchers.filter((lang) => lang.code !== activeLang);

  console.log(switchers.find((language) => language?.code === activeLang));

  console.log(switchers);
  console.log(activeLang);

  return (
    <>
      <div className={`${styles.lang_select} ${open && styles.background}`}>
        <ul className={`${open ? styles.options : styles.options_hide}`}>
          {swithcerFiltered.map(({ code, icon }) => (
            <li key={code}>
              <button
                className={styles.switcher}
                onClick={() => changeLanguage(code)}
              >
                {icon}
              </button>
            </li>
          ))}
        </ul>
        <button className={styles.switcher} onClick={handleOpenSelect}>
          {switchers.find((language) => language?.code === activeLang)?.icon}
        </button>
      </div>
    </>
  );
};

export default LangSwitcher;
