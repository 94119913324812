import React, { useState } from "react";
import styles from "./sidebar-form.module.scss";
import QuestionForm from "../QuestionForm";
import { useTranslation } from "react-i18next";

const SidebarForm = () => {
  const { t } = useTranslation("translation", { keyPrefix: "FORM" });
  const [types, setTypes] = useState([
    {
      id: 1,
      text: "inSolesOption",
      isSelected: false,
    },
    {
      id: 2,
      text: "insoleOptions",
      isSelected: false,
    },
    {
      id: 3,
      text: "suitable",
      isSelected: false,
    },
  ]);

  const handleSelectTypes = (typeId) => {
    const updated = [...types].map((type) =>
      type.id === typeId
        ? { ...type, isSelected: true }
        : { ...type, isSelected: false }
    );
    setTypes(updated);
  };

  console.log(
    "types.for",
    types.forEach((item) => item.isSelected)
  );
  return (
    <div className={styles.form}>
      <ul className={styles.list}>
        {types.map(({ id, text, isSelected }) => (
          <li>
            <p
              className={`${styles.type} ${isSelected && styles.isActive}`}
              onClick={() => handleSelectTypes(id)}
            >
              {t(`${text}`)}
            </p>
          </li>
        ))}
      </ul>
      <br />
      <QuestionForm
        activeTypeId={
          types.find((type) => Boolean(type.isSelected))?.id || null
        }
      />
    </div>
  );
};

export default SidebarForm;
