import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import Button from "../Button/";
import { ReactComponent as Arrow2 } from "../../assets/img/arrow2.svg";
import { ReactComponent as SmallArrow } from "../../assets/img/arrowmobile2.svg";
import InSoles2 from "../../assets/img/insoles-removebg-removebg-preview.png";
import { ReactComponent as Line } from "../../assets/img/Line.svg";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import styles from "./promo-slider.module.scss";
import { useTranslation } from "react-i18next";
import { SideBarContext } from "../../App";
import { SIDEBAR_FORM_TYPE } from "../../assets/constants/sideBar";
import { InsoleModel } from "../InsoleModel/InsoleModel";

const PromoSlider = () => {
  const { setSideBarOptions } = useContext(SideBarContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);

  const [isHover, setIsHover] = useState(false);

  const { t } = useTranslation("translation", { keyPrefix: "PROMO_SLIDER" });

  // const slides = [
  //   {
  //     color: t("colors"),
  //     materials: t("materials"),
  //     created: t("created"),
  //     by: t("by"),
  //     nature: t("nature"),
  //     itself: t("itself"),
  //     text2: t("text_2"),
  //     id: 1,
  //   },
  //   {
  //     color: "tes2",
  //     materials: "test2",
  //     created: t("created"),
  //     by: t("by"),
  //     nature: t("nature"),
  //     itself: t("itself"),
  //     text2: t("text_2"),
  //     id: 2,
  //   },
  //   {
  //     color: "tes3t",
  //     materials: "test3",
  //     created: t("created"),
  //     by: t("by"),
  //     nature: t("nature"),
  //     itself: t("itself"),
  //     text2: t("text_2"),
  //     id: 3,
  //   },
  // ];

  const slides = [
    {
      id: 1,
      text: (
        <div
          className={`${styles.slide_text} ${isHover && styles.color_white} `}
        >
          {t("colorsAndMaterial")}{" "}
          <span
            className={`${styles.color_white} ${
              isHover && styles.color_orange
            }`}
          >
            {t("created")}
          </span>{" "}
          {t("by")}&nbsp;
          <span
            className={`${styles.color_white} ${
              isHover && styles.color_orange
            }`}
          >
            {t("nature")}
          </span>
          &nbsp; {t("itself")}
        </div>
      ),
      secondText: t("des1"),
    },
    {
      id: 2,
      text: (
        <div
          className={`${styles.slide_text} ${isHover && styles.color_white} `}
        >
          {t("useit")}{" "}
          <span
            className={`${styles.color_white} ${
              isHover && styles.color_orange
            }`}
          >
            {t("woomens")}
          </span>{" "}
          <span
            className={`${styles.color_white} ${
              isHover && styles.color_orange
            }`}
          >
            {t("model")}
          </span>{" "}
          {t("shoes")}
        </div>
      ),
      secondText: t("des2"),
    },
    {
      id: 3,
      text: (
        <div
          className={`${styles.slide_text} ${isHover && styles.color_white} `}
        >
          <span
            className={`${styles.color_white} ${
              isHover && styles.color_orange
            }`}
          >
            {t("noneed")}
          </span>{" "}
          {t("adjust")}
        </div>
      ),
      secondText: t("des3"),
    },
  ];

  console.log(slides.length === currentSlideIndex);

  console.log(currentSlideIndex);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentSlideIndex === 3) {
        setCurrentSlideIndex(1);
      } else {
        setCurrentSlideIndex((prev) => prev + 1);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [slides.length, currentSlideIndex]);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className} ${styles.swiperPaginationBullet} "></span>`;
    },
  };
  const openApplicationForm = () => {
    setSideBarOptions({
      isOpen: true,
      formType: SIDEBAR_FORM_TYPE.APPLICATION,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <main>
      <section className={`container ${styles.wrapper}`}>
        <Swiper className="mySwiper">
          <SwiperSlide>
            <section className={styles.slide}>
              <h1 className={styles.title} style={{ color: "white" }}>
                {t("title")}
                <br />
                <p>{t("sub_title")}</p>
              </h1>
              <div className={styles.center}>
                {/* <img className={styles.slide_img} src={InSoles2} alt="" /> */}
                <InsoleModel className={styles.slide_img} />
              </div>

              <div className={styles.slide_desc__left}>
                <h1>
                  {t("innovative")}&nbsp;
                  <span>{t("technology")}</span>
                  <br />
                  {t("creation")}
                </h1>
                <p>{t("text")}</p>
              </div>

              <div className={styles.slide_desc__right}>
                <Arrow2 className={styles.arrow} />

                <SmallArrow className={styles.smallArrow} />
                <ul className={styles.slider}>
                  {slides.map(({ text, secondText, id }, index) => (
                    <li
                      key={id}
                      className={`${
                        currentSlideIndex === id ? styles.active : ""
                      }`}
                    >
                      {text}
                      <p className={styles.secondText}>{secondText}</p>
                    </li>
                  ))}
                </ul>
                <ul className={styles.button_list}>
                  {slides.map(({ id }) => (
                    <li>
                      <button
                        onClick={() => setCurrentSlideIndex(id)}
                        className={`${styles.button} ${
                          id === currentSlideIndex ? styles.button_active : ""
                        }`}
                      ></button>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </SwiperSlide>
        </Swiper>
        <Button
          className={styles.button_submite}
          onClick={openApplicationForm}
        />
      </section>
    </main>
  );
};

export default PromoSlider;
