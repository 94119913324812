import React from "react";
import { ReactComponent as Arrow } from "../../assets/img/arrow.svg";
import styles from "./button.module.scss";
import { useTranslation } from "react-i18next";

const Button = ({ type = "DEFAULT", className, ...rest }) => {
  const { t } = useTranslation("translation", { keyPrefix: "BUTTON" });
  return (
    <button
      className={`${styles.button} ${className}`}
      type={
        type === "SUBMIT_EMAIL" || type === "APPLICATION_FORM"
          ? "submit"
          : "button"
      }
      style={
        type === "APPLICATION_FORM"
          ? { maxWidth: "350px", gap: "20px", marginTop: 24 }
          : {}
      }
      {...rest}
    >
      {type === "DEFAULT" && <p>{t("default")}</p>}
      {type === "SUBMIT_EMAIL" && (
        <div className={styles.form_btn}>
          <h3>{t("sendEmail")}</h3>
          <p>{t("qa")}</p>
        </div>
      )}
      {type === "APPLICATION_FORM" && (
        <div className={styles.application_btn}>
          <h3>{t("sendRequest")}</h3>
          <p style={{ width: 211 }}>{t("agree")}</p>
        </div>
      )}
      <Arrow />
    </button>
  );
};

export default Button;
