import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styles from "./application-form.module.scss";
import CustomSelect from "../../Select";
import Button from "../../Button";
import { useForm } from "react-hook-form";
import { __API__ } from "../../../api";
import axios from "axios";
import Modal from "../../Modal";

const ApplicationForm = () => {
  const { t } = useTranslation("translation", { keyPrefix: "FORM" });
  const [isModal, setIsModal] = useState(false);
  const [isSuccess, setIsSucces] = useState(true);

  const [value, setValue] = useState("");
  const [height, setHeight] = useState("auto");

  const handleChange = (event) => {
    setValue(event.target.value);
    setHeight(`${event.target.scrollHeight}px`);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { email, country, type, comment } = data;
    try {
      await axios
        .post(`${__API__}/api/application-form`, {
          email,
          country,
          type,
          comment,
        })
        .then(() => {
          setIsModal(true);
          setIsSucces(true);
        });
    } catch {
      setIsModal(true);
      setIsSucces(false);
    }
  };

  return (
    <>
      {isModal && <Modal isSuccess={isSuccess} onClose={setIsModal} />}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h1>{t("submite")}</h1>
        <div className={styles.inputs}>
          <input
            type="email"
            placeholder={i18next.t("FORM.email")}
            {...register("email")}
            required
          />
          <input
            type="country"
            placeholder={i18next.t("FORM.country")}
            {...register("country")}
            required
          />

          <CustomSelect control={control} />
          <textarea
            className={styles.textarea}
            placeholder={i18next.t("FORM.comment")}
            {...register("comment")}
            required
            style={{ height: height }}
            onChange={handleChange}
            value={value}
          />
        </div>
        <Button className={styles.button} type="APPLICATION_FORM" />
      </form>
    </>
  );
};

export default ApplicationForm;
