import Footer from "./components/Footer";
import Header from "./components/Header";
import PromoSlider from "./components/PromoSlider";
import VideoPromo from "./components/VideoPromo";
import InSolesLayers from "./components/InSolesLayers";
import HealthyJourney from "./components/HealthyJourney";
import SideBar from "./components/Sidebar";
import { createContext, useEffect, useState } from "react";
import { SIDEBAR_FORM_TYPE } from "./assets/constants/sideBar";

export const SideBarContext = createContext(null);

function App() {
  const [sideBarOptions, setSideBarOptions] = useState({
    isOpen: false,
    formType: SIDEBAR_FORM_TYPE.SIDEBAR_FORM,
  });

  useEffect(() => {
    if (sideBarOptions.isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [sideBarOptions.isOpen]);
  return (
    <SideBarContext.Provider value={{ sideBarOptions, setSideBarOptions }}>
      <Header />
      <PromoSlider />
      <VideoPromo />
      <InSolesLayers />
      <HealthyJourney />
      <Footer />
      <SideBar />
    </SideBarContext.Provider>
  );
}

export default App;
