import React from "react";

import styles from "./insoles-layers.module.scss";

import sprite from "../../assets/img/sprite.svg";

import InSolesSlider from "./InSolesSlider";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import { useIsVisibleToScroll } from "../../hooks/useIsVisibleToScroll";

const InSolesLayers = () => {
  const { t } = useTranslation("translation", { keyPrefix: "INSOLES" });
  const [isHoverSecondImage, setIsHoverSecondImage] = useState(false);
  const [isHoverThreeImage, setIsHoverThreeImage] = useState(false);
  const [isHoverFirstImage, setIsHoverFirstImage] = useState(false);
  const elementRef = useRef(null);

  const { isVisible } = useIsVisibleToScroll(elementRef);

  const handleMouseEnter = () => {
    setIsHoverSecondImage(true);
  };

  const handleMouseLeave = () => {
    setIsHoverSecondImage(false);
  };

  const classNameFirstInsoleImage = `${styles.insoleImage} ${
    styles.firstInsoleImg
  } ${isHoverSecondImage ? styles.test1 : ""} ${isVisible ? styles.asdf : ""} ${
    isVisible ? styles.first : ""
  }`;

  const classNameThreeInsoleImage = `${styles.insoleImage} ${
    styles.threeInsoleImg
  }  ${isHoverSecondImage ? styles.test2 : ""} ${
    isVisible ? styles.asdf : ""
  } ${isVisible ? styles.three : ""}`;

  return (
    <div className={styles.insole}>
      <div className="container">
        <div className={styles.insoleImageBlock}>
          <div className={`${styles.insoleBlock} ${styles.insoleTextTop}`}>
            <div className={styles.insoleCaption}>
              <div className={styles.insoleCaptionBlock}>
                <span
                  className={`${styles.insoleCaptionFirst} ${
                    isHoverFirstImage ? styles.colorOrange : ""
                  }`}
                >
                  {t("topLayers")}{" "}
                </span>
                <span
                  className={`${styles.insoleCaptionSecond} ${
                    isHoverFirstImage ? styles.insoleCaptionSecondHover : ""
                  }`}
                >
                  {t("choose")}
                </span>
              </div>
              <svg
                className={`${styles.insolePath} ${styles.pathTop} ${
                  isHoverFirstImage ? styles.insolePathHover : ""
                }`}
              >
                <use xlinkHref={`${sprite}#pathTop`}></use>
              </svg>
              <svg className={`${styles.insolePath} ${styles.pathTopm}`}>
                <use xlinkHref={`${sprite}#pathTopm`}></use>
              </svg>
            </div>
            <span className={styles.insoleText}>{t("text1")}</span>
          </div>
          <div className={styles.imagesContainer}>
            <div
              onMouseLeave={() => setIsHoverFirstImage(false)}
              onMouseEnter={() => setIsHoverFirstImage(true)}
              className={classNameFirstInsoleImage}
            ></div>
            <div
              ref={elementRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`${styles.insoleImage} ${styles.secondInsoleImg}  `}
            ></div>
            <div
              onMouseLeave={() => setIsHoverThreeImage(false)}
              onMouseEnter={() => setIsHoverThreeImage(true)}
              className={classNameThreeInsoleImage}
            ></div>
          </div>
          <div className={`${styles.insoleBlock} ${styles.insoleTextBottom}`}>
            <span className={styles.insoleCaption}>
              <svg
                className={`${styles.insolePath} ${styles.pathBottom} ${
                  isHoverSecondImage ? styles.insolePathHover : ""
                }`}
              >
                <use xlinkHref={`${sprite}#pathBottom`}></use>
              </svg>
              <svg className={`${styles.insolePath} ${styles.pathBottomm}`}>
                <use xlinkHref={`${sprite}#pathBottomm`}></use>
              </svg>
              <div className={styles.insoleCaptionBlock}>
                <span
                  className={`${styles.insoleCaptionFirst} ${
                    styles.middle_text
                  } ${isHoverSecondImage ? styles.colorOrange : ""}`}
                >
                  {t("middle")}
                </span>
              </div>
            </span>
            <span className={`${styles.insoleText} ${styles.desc2}`}>
              {t("text2")}
            </span>
          </div>

          <div className={`${styles.insoleBlock} ${styles.insoleTextRight}`}>
            <div className={styles.insoleCaption}>
              <svg
                className={`${styles.insolePath} ${styles.pathRight} ${
                  isHoverThreeImage ? styles.insolePathHover : ""
                }`}
              >
                <use xlinkHref={`${sprite}#pathRight`}></use>
              </svg>
              <svg className={`${styles.insolePath} ${styles.pathRightm} `}>
                <use xlinkHref={`${sprite}#pathRightm`}></use>
              </svg>
              <div className={`${styles.insoleCaptionBlock} ${styles.start}`}>
                <span
                  className={`${styles.insoleCaptionFirst} ${
                    isHoverThreeImage ? styles.colorOrange : ""
                  }`}
                >
                  {t("bottom")}
                </span>
                <span
                  className={`${styles.insoleCaptionSecond} ${
                    isHoverThreeImage ? styles.insoleCaptionSecondHover : ""
                  } `}
                >
                  {" "}
                  {t("base")}
                </span>
              </div>
            </div>
            <span className={styles.insoleText}>{t("text3")}</span>
          </div>
        </div>
        <InSolesSlider />
      </div>
    </div>
  );
};

export default InSolesLayers;
