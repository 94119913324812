import { OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Insole } from "../../models/Insole";

import styles from "./InsoleModel.module.scss";

export const InsoleModel = ({ className }) => {
  return (
    <Canvas className={`${className} ${styles.canvas}`}>
      <OrbitControls enableZoom={false} enablePan={false} />
      <hemisphereLight intensity={1} />
      <directionalLight />
      <spotLight
        position={[10, 10, 10]}
        angle={1}
        penumbra={1}
        intensity={1}
        castShadow
      />
      <Suspense fallback={null}>
        <Insole />
      </Suspense>
    </Canvas>
  );
};
