import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./video-promo.module.scss";

const VideoPromo = () => {
  const { t } = useTranslation("translation", { keyPrefix: "VIDEO_PROMO" });
  return (
    // todo:
    // add video as a background
    <section className={`${styles.wrapper}`}>
      <div className="container">
        <h1 className={styles.title}>
          {t("title")}
          <span>{t("printer")}</span> <br />
          {t("title_v2")}
        </h1>
        <p>{t("desc")}</p>
      </div>
    </section>
  );
};

export default VideoPromo;
