import { useContext, useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import styles from "./insoles-slider.module.scss";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import { INITIAL_SLIDES } from "../../../assets/constants/slides";
import { SideBarContext } from "../../../App";
import { SIDEBAR_FORM_TYPE } from "../../../assets/constants/sideBar";

const BREAK_POINTS = {
  391: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  576: {
    slidesPerView: 2,
  },
  1025: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
};

const InSolesSlider = () => {
  const { setSideBarOptions } = useContext(SideBarContext);
  const { t } = useTranslation("translation", { keyPrefix: "INSOLES" });
  const [slides, setSlides] = useState(INITIAL_SLIDES);

  useEffect(() => {
    setSlides(INITIAL_SLIDES);
  }, [t]);

  function changeSole(slideId, image) {
    setSlides((prevSlides) => {
      return prevSlides.map((slide) => {
        if (slide.id === slideId) {
          return {
            ...slide,
            image: image,
          };
        } else {
          return slide;
        }
      });
    });
  }
  const pagination = {
    clickable: true,
    renderBullet: function (_, className) {
      return `<span class="${className} ${styles.swiperPaginationBullet} "></span>`;
    },
  };

  const openApplicationForm = () => {
    setSideBarOptions({
      isOpen: true,
      formType: SIDEBAR_FORM_TYPE.APPLICATION,
    });
  };

  console.log(slides, "slides");
  const renderSlides = useMemo(
    () =>
      slides.map((slide) => (
        <>
          <SwiperSlide key={slide.id}>
            <div className={styles.card_wrapper}>
              <div className={styles.card}>
                <img
                  src={slide.image}
                  alt={slide.caption}
                  className={styles.soleImage}
                />
                <p className={styles.soleSlideCaption}>
                  {t(`${slide.caption}`)}
                </p>
                <p className={styles.soleSlideDescription}>
                  {t(`${slide.description}`)}
                </p>
                <ul className={styles.soleColorList}>
                  {slide.colors.map((color, index) => (
                    <li
                      className={styles.soleColor}
                      onClick={() => changeSole(slide.id, color.image)}
                      onMouseEnter={() => changeSole(slide.id, color.image)}
                      key={`${color.id}_${index}`}
                      style={{ backgroundColor: color.code }}
                    ></li>
                  ))}
                </ul>
              </div>
            </div>
            <Button
              className={styles.hover_btn}
              onClick={openApplicationForm}
            />
          </SwiperSlide>
        </>
      )),
    [slides, t]
  );

  return (
    <>
      <Swiper
        className={styles.insoleSwiper}
        modules={[Pagination]}
        breakpoints={BREAK_POINTS}
        slidesPerView={"auto"}
      >
        {renderSlides}
      </Swiper>
    </>
  );
};
export default InSolesSlider;
