import React, { useContext, useState } from "react";
import styles from "./question-form.module.scss";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import { SideBarContext } from "../../../App";
import { useForm } from "react-hook-form";
import axios from "axios";
import { __API__ } from "../../../api";
import Modal from "../../Modal";

const QuestionForm = ({ inputStyles = {}, activeTypeId }) => {
  const { t } = useTranslation("translation", { keyPrefix: "FOOTER" });
  const { sideBarOptions } = useContext(SideBarContext);
  const [isModal, setIsModal] = useState(false);
  const [isSuccess, setIsSucces] = useState(true);
  const [value, setValue] = useState("");
  const [height, setHeight] = useState("auto");

  const handleChange = (event) => {
    setValue(event.target.value);
    setHeight(`${event.target.scrollHeight}px`);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { email, question } = data;
    const sendData = { email, question };

    if (activeTypeId) sendData[activeTypeId] = sendData;
    try {
      await axios.post(`${__API__}/api/question-form`, sendData).then(() => {
        setIsModal(true);
        setIsSucces(true);
      });
    } catch {
      setIsModal(true);
      setIsSucces(false);
    }
  };

  return (
    <>
      {isModal && <Modal isSuccess={isSuccess} onClose={setIsModal} />}
      <form
        className={`${styles.form} ${
          !sideBarOptions.isOpen ? styles.noMargin : ""
        }`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="text"
          placeholder={t("placeholder_EMAIL")}
          style={inputStyles}
          {...register("email")}
          className={styles.input}
        />
        <textarea
          type="text"
          placeholder={t("placeholder_QUESTION")}
          className={`${styles.textarea} ${styles.input}`}
          style={inputStyles}
          {...register("question")}
          onChange={handleChange}
          value={value}
        />

        <Button className={styles.button} type="SUBMIT_EMAIL" />
      </form>
    </>
  );
};

export default QuestionForm;
