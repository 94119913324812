import React from "react";
import Select, { components } from "react-select";
import { ReactComponent as DropDownIcon } from "../../assets/img/dropdownIcon.svg";
import i18next from "i18next";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CustomSelect = ({ control }) => {
  const { t } = useTranslation("translation", { keyPrefix: "INSOLES" });

  const options = [
    { value: 1, label: t("GLeather") },
    { value: 2, label: t("suede") },
    { value: 3, label: t("alcantara") },
    { value: 4, label: t("microFiber") },
  ];
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DropDownIcon />
      </components.DropdownIndicator>
    );
  };

  const getColor = (state) => {
    if (state.isSelected) {
      return "transparent";
    }
    if (state.isSelected && state.isFocused) {
      return "transparent";
    }
    if (state.isFocused) {
      return "#404040";
    }
  };

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      background: getColor(state),
      cursor: "pointer",
      "&:active": {
        background: "transparent",
      },
      "&:hover": {
        background: "#404040",
      },
    }),
    control: (provided) => ({
      ...provided,
      background: "transparent",
      display: "flex",
      flexWrap: "nowrap",
      borderColor: "#2e2e2e",
      width: "100%",
      paddingLeft: "22px",
      minWidth: "320px",
      maxHeight: "63px",
      paddingTop: "14px",
      paddingBottom: "11px",
      boxShadow: "none",
      cursor: "pointer",
      "&:hover": {
        borderColor: "none",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      background: "#151515",
      width: "100%",
      color: "white",
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "uppercase",
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "0px",
      paddingRight: "0px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "20px",
      fontWeight: 300,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#404040",
      textTransform: "uppercase",
      fontSize: "20px",
      fontWeight: 700,
    }),
  };
  return (
    <Controller
      name="type"
      control={control}
      render={({ field: { onChange } }) => (
        <Select
          required
          options={options}
          styles={selectStyles}
          isSearchable={false}
          placeholder={i18next.t("FORM.inSolesOption")}
          components={{ DropdownIndicator }}
          onChange={(selectedOption) => onChange(selectedOption.label)}
        />
      )}
    />
  );
};

export default CustomSelect;
